// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-blog-1-js": () => import("/opt/build/repo/src/pages/blog/blog1.js" /* webpackChunkName: "component---src-pages-blog-blog-1-js" */),
  "component---src-pages-blog-blog-10-js": () => import("/opt/build/repo/src/pages/blog/blog10.js" /* webpackChunkName: "component---src-pages-blog-blog-10-js" */),
  "component---src-pages-blog-blog-2-js": () => import("/opt/build/repo/src/pages/blog/blog2.js" /* webpackChunkName: "component---src-pages-blog-blog-2-js" */),
  "component---src-pages-blog-blog-3-js": () => import("/opt/build/repo/src/pages/blog/blog3.js" /* webpackChunkName: "component---src-pages-blog-blog-3-js" */),
  "component---src-pages-blog-blog-4-js": () => import("/opt/build/repo/src/pages/blog/blog4.js" /* webpackChunkName: "component---src-pages-blog-blog-4-js" */),
  "component---src-pages-blog-blog-5-js": () => import("/opt/build/repo/src/pages/blog/blog5.js" /* webpackChunkName: "component---src-pages-blog-blog-5-js" */),
  "component---src-pages-blog-blog-6-js": () => import("/opt/build/repo/src/pages/blog/blog6.js" /* webpackChunkName: "component---src-pages-blog-blog-6-js" */),
  "component---src-pages-blog-blog-7-js": () => import("/opt/build/repo/src/pages/blog/blog7.js" /* webpackChunkName: "component---src-pages-blog-blog-7-js" */),
  "component---src-pages-blog-blog-8-js": () => import("/opt/build/repo/src/pages/blog/blog8.js" /* webpackChunkName: "component---src-pages-blog-blog-8-js" */),
  "component---src-pages-blog-blog-9-js": () => import("/opt/build/repo/src/pages/blog/blog9.js" /* webpackChunkName: "component---src-pages-blog-blog-9-js" */),
  "component---src-pages-blog-index-js": () => import("/opt/build/repo/src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-bishop-js": () => import("/opt/build/repo/src/pages/portfolio/bishop.js" /* webpackChunkName: "component---src-pages-portfolio-bishop-js" */),
  "component---src-pages-portfolio-headbutt-js": () => import("/opt/build/repo/src/pages/portfolio/headbutt.js" /* webpackChunkName: "component---src-pages-portfolio-headbutt-js" */),
  "component---src-pages-portfolio-index-js": () => import("/opt/build/repo/src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-portfolio-nielsen-js": () => import("/opt/build/repo/src/pages/portfolio/nielsen.js" /* webpackChunkName: "component---src-pages-portfolio-nielsen-js" */),
  "component---src-pages-portfolio-shuttle-js": () => import("/opt/build/repo/src/pages/portfolio/shuttle.js" /* webpackChunkName: "component---src-pages-portfolio-shuttle-js" */),
  "component---src-pages-portfolio-teebox-js": () => import("/opt/build/repo/src/pages/portfolio/teebox.js" /* webpackChunkName: "component---src-pages-portfolio-teebox-js" */),
  "component---src-pages-portfolio-uplink-js": () => import("/opt/build/repo/src/pages/portfolio/uplink.js" /* webpackChunkName: "component---src-pages-portfolio-uplink-js" */),
  "component---src-pages-portfolio-wyndham-js": () => import("/opt/build/repo/src/pages/portfolio/wyndham.js" /* webpackChunkName: "component---src-pages-portfolio-wyndham-js" */),
  "component---src-pages-success-js": () => import("/opt/build/repo/src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

